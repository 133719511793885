import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";

import Hero from "./hero";
import AboutMe from "./aboutMe";
import Experience from "./experience";
import Projects from "./projects";
import Publications from "./publications";
import Contact from "./contact";
import Footer from "./footer"

import 'normalize.css';

const IndexPage = ({ data }) => {

  const projects = data.allStrapiProject.nodes;
  const aboutMe = data.allStrapiAboutMe.nodes[0];
  const publications = data.allStrapiPublication.nodes;
  const jobs = data.allStrapiJob.nodes;

  console.log(data)

  return (
    <>
    <Helmet>
      <title>Shane Ducksbury | Developer</title>
    </Helmet>
    {
      data ? 
        <div className="site-wrapper">
          <Hero />
          <Projects allProjects={ projects }/>
          <AboutMe aboutMeContent={ aboutMe }/>
          <Experience allExperience = { jobs }/>
          <Publications allPublications={ publications }/>
          <Contact />
          <Footer />
        </div>
      : <p>Data Loading...</p>
    }
    </>
  )
}

export default IndexPage

export const pageQuery = graphql `query PageContentQuery {
  allStrapiPublication {
    nodes {
      Description
      Link
      id
      Title
      PublicationShot {
        url
      }
    }
  }
  allStrapiProject {
    nodes {
      Description
      GithubLink
      Title
      DemoLink
      Tools
      ProjectScreenshot {
        url
      }
      id
      DateCreated
      projectPath: gatsbyPath(filePath: "/projects/{strapiProject.Title}")
    }
  }
  allStrapiAboutMe {
    nodes {
      Information {
        data {
          Information
        }
      }
      Languages
      Headline
      Headshot {
        url
      }
    }
  }
  allStrapiJob {
    nodes {
      Responsibilities
      Workplace
      StartDate
      Role
      EndDate
    }
  }
}`